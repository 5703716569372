export const fileOperations = {
  methods: {
    sizeFormatter(size) {
      // #2**10 = 1024
      const power = 2**10
      var n = 0
      var powerN = {0 : 'B', 1: 'KB', 2: 'MB', 3: 'GB', 4: 'TB'}
      while (size > power) {
          size /=  power
          n += 1
      }
      size = Math.round(size * 10) / 10

      return size.toString() + ' ' + powerN[n]
    }
  }
}


export const calculOperations = {
  methods: {
    calculateMontantAllocation(nbPersonnesFoyer, revenusBrutGlobal) {
      switch(nbPersonnesFoyer) {
        case 1:
          if (revenusBrutGlobal <= 15000) {
            return 1000
          }
          else {
            return 500
          }
        case 2:
          if (revenusBrutGlobal <= 25000) {
            return 1000
          }
          else {
            return 500
          }
        default:
          if (revenusBrutGlobal <= (25000 + (nbPersonnesFoyer - 2)*5000)) {
            return 1000
          }
          else {
            return 500
          }
      }
    }
  }
}

export const dateOperations = {
  methods: {
    calculateAge(birthdate) {
      var today = new Date();
      var birthDate = new Date(birthdate);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age = age - 1;
      }
      return age;
    },

    calculateDecimalAge(birthdate) {
      var today = new Date();
      var birthDate = new Date(birthdate);
      var oneDay = 24 * 60 * 60 * 1000;
      var diffDays = (today - birthDate) / oneDay;
      return diffDays / 365
    },

    ageInferiorToLimitDate(birthdate, ageMax, limitDate) {
      const birthdateTimestamp = birthdate.getTime()
      const limitTimestamp = limitDate.getTime()
      const ageTimestamp = limitTimestamp - birthdateTimestamp
    
      const ageInYears = ageTimestamp / (1000 * 60 * 60 * 24 * 365.25);
    
      return ageInYears < ageMax;
    },

    monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    }
  }
}
