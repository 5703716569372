<template>
  <div class="ConseillerRemboursementProfile" >
    <formTitleComponent />
      <div class="container-fluid">
        <h1 class="form_div_title text-center">
          Espace conseiller -
          Remboursement de {{monthRender(remboursement.month)}} {{remboursement.year}}  de {{ remboursement.beneficiaire.user.first_name }}
          {{ remboursement.beneficiaire.user.last_name}}
        </h1>
        <div class="form_div">
          <div class="form_content">

            <!-- ERROR MODAL -->
            <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal" hide-footer title="Erreur lors du chargement de votre profil">
              <div class="text-center">
                <p>Une erreur s'est produite lors de la communication avec le serveur,
                veuillez vérifier réessayer</p>
                <b-button class="mt-3" variant="outline-success" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
              </div>
            </b-modal>

            <b-modal ref="munaExistingErrorModal" id="munaExistingErrorModal" hide-footer title="Numéro de Muna déjà existant" size="lg">
              <div class="text-center">
                <p>Ce numéro de Muna est déjà enregistré pour un autre bénéficiaire</p>
                <p> Veuillez vérifier le numéro de Muna et, le cas échéant, rechercher via votre tableau de bord le bénéficiaire déjà
                  associé à ce numéro de Muna.</p>
                <b-button pill block class="mt-2" variant="outline-dark" hide-footer @click="$bvModal.hide('munaExistingErrorModal')">Fermer</b-button>
              </div>
            </b-modal>

            <!-- Validated Remboursement -->
            <b-modal ref="validateRemboursementModal" id="validateRemboursementModal" size="lg" hide-footer>
              <template v-slot:modal-title>
                <strong> Valider la demande de remboursement du bénéficiaire </strong>
              </template>
              <p> Vous pouvez valider la demande de remboursement du bénéficiaire. Un manager devra confirmer la validation pour
                que le bénéficiaire.
              </p>
              <p>
                Montant remboursé sur l'année {{remboursement.year}} : <strong>{{formatThousandSeparatorNumber(parseFloat(totalMontantRembourseInYear).toFixed(2))}} €</strong>
              </p>
              <b-form>
                <p>
                  <strong> Montant total alloué : {{montantTotal}} €</strong>
                  <span class="text-danger" v-if="isErrorMessageTotalMontant">
                    <br>Attention, dépassement des {{montantPlafond}} € sinon
                  </span>
                </p>
                <!--<b-form-group id="validate-remboursement-montant-group" label="Montant alloué" label-for="validate-remboursement-montant-input">
                  <b-form-input
                    required
                    v-model="validateRemboursementMontant"
                    placeholder="Montant en €"
                    id="validate-remboursement-montant-input"
                    type="number"
                    min="0" max="1000000" step=".01"
                    @blur="validateRemboursementMontantCorrection"
                  />
                </b-form-group>-->

                <div v-if="remboursement.want_frais_deplacement">
                  <b-form-checkbox
                    v-model="validateRemboursementAreFraisDeplacementVaidated"
                    id="are_frais_deplacement_validated">
                    La prise en charge des frais de déplacement est validée
                  </b-form-checkbox>

                  <div v-if="validateRemboursementAreFraisDeplacementVaidated" class="mt-2">

                    <p>
                      <strong> Montant total alloué : {{montantFraisSupTotal}} €</strong>
                      <span class="text-danger" v-if="isErrorMessageTotalMontantFraisSup">
                        <br>Attention, dépassement des 1000 € sinon
                      </span>
                    </p>
                  </div>

                  <div v-else class="mt-2">
                    <b-form-group id="validate-remboursementrefuse-frais-sup-beneficiaire-message-group" label="Message de refus pour les frais supplémentaires (facultatif)"
                                  label-for="alidate-remboursementrefuse-frais-sup-beneficiaire-message-input">
                      <b-form-textarea
                        required v-model="refuseRemboursementFraisDeplacementMessage"
                        placeholder="Renseignez les raisons de votre refus pour les frais supplémentaires"
                        id="ralidate-remboursementrefuse-frais-sup-beneficiaire-message-input"
                        maxlength="999"
                        rows="7"
                        @input="$v.refuseRemboursementFraisDeplacementMessage.$touch()"
                        :state="$v.refuseRemboursementFraisDeplacementMessage.$dirty ? !$v.refuseRemboursementFraisDeplacementMessage.$error : null"/>
                    </b-form-group>
                  </div>
                </div>

                <b-button
                  block :variant="validateRemboursementButtonStyle" class="mt-2"
                  :disabled="validateRemboursementInProcess"
                  @click='validateRemboursement'>
                    <b-spinner small label="validate remboursement spinner" v-if="validateRemboursementInProcess"></b-spinner>
                    {{ validateRemboursementButtonText }}
                </b-button>
              </b-form>
            </b-modal>

            <b-modal ref="validatedRemboursementModal" id="validatedRemboursementModal" size="lg" hide-footer title="Vous avez bien validé la demande de remboursement du bénéficiaire">
              <div class="text-center">
                <p>
                  <strong>Vous avez bien validé la demande de remboursement du bénéficiaire.
                    La demande est maintenant en attente de validation de la part d'un manager.
                  </strong>
                </p>
                <b-button class="mt-3" variant="outline-success" @click="$bvModal.hide('validatedRemboursementModal')">Fermer</b-button>
              </div>
            </b-modal>

            <b-modal ref="refuseRemboursementModal" id="refuseRemboursementModal" size="lg" hide-footer>
              <template v-slot:modal-title>
                <strong> Refuser la demande de remboursement fournie par le bénéficiaire </strong>
              </template>
              <b-form>
                <b-form-group id="refuse-beneficiaire-message-group" label="Raisons du refus" label-for="refuse-beneficiaire-message-input">
                  <b-form-textarea
                    required v-model="refuseRemboursementMessage"
                    placeholder="Renseignez les raisons de votre refus."
                    id="refuse-beneficiaire-message-input"
                    maxlength="999"
                    rows="7"
                    @input="$v.refuseRemboursementMessage.$touch()"
                    :state="$v.refuseRemboursementMessage.$dirty ? !$v.refuseRemboursementMessage.$error : null"/>
                </b-form-group>

                <b-form-group id="refuse-frais-sup-beneficiaire-message-group" label="Message de refus pour les frais supplémentaires (facultatif)"
                              label-for="refuse-frais-sup-beneficiaire-message-input">
                  <b-form-textarea
                    required v-model="refuseRemboursementFraisDeplacementMessage"
                    placeholder="Renseignez les raisons de votre refus pour les frais supplémentaires"
                    id="refuse-frais-sup-beneficiaire-message-input"
                    maxlength="999"
                    rows="7"
                    @input="$v.refuseRemboursementFraisDeplacementMessage.$touch()"
                    :state="$v.refuseRemboursementFraisDeplacementMessage.$dirty ? !$v.refuseRemboursementFraisDeplacementMessage.$error : null"/>
                </b-form-group>
                <b-button
                  block :variant="refuseRemboursementButtonStyle" class="mt-2"
                  :disabled="refuseRemboursementInProcess || $v.refuseRemboursementMessage.$invalid || $v.refuseRemboursementMessage.$invalid"
                  @click='refuseRemboursement'>
                    <b-spinner small label="refuse beneficiaire Spinner" v-if="refuseRemboursementInProcess"></b-spinner>
                    {{ refuseRemboursementButtonText }}
                </b-button>
              </b-form>
            </b-modal>

            <b-modal ref="incompleteRemboursementModal" id="incompleteRemboursementModal" size="lg" hide-footer>
              <template v-slot:modal-title>
                <strong> Marquer la demande de remboursement comme incomplète </strong>
              </template>
              <b-form>
                <b-form-group id="incomplete-beneficiaire-message-group" label="Raisons du refus" label-for="incomplete-beneficiaire-message-input">
                  <b-form-textarea
                    required v-model="refuseRemboursementMessage"
                    placeholder="Renseignez les raisons de votre refus."
                    id="incomplete-beneficiaire-message-input"
                    maxlength="999"
                    rows="7"
                    @input="$v.refuseRemboursementMessage.$touch()"
                    :state="$v.refuseRemboursementMessage.$dirty ? !$v.refuseRemboursementMessage.$error : null"/>
                </b-form-group>
                <b-form-group id="incomplete-frais-sup-beneficiaire-message-group" label="Message de refus pour les frais supplémentaires (facultatif)"
                              label-for="incomplete-frais-sup-beneficiaire-message-input">
                  <b-form-textarea
                    required v-model="refuseRemboursementFraisDeplacementMessage"
                    placeholder="Renseignez les raisons de votre refus pour les frais supplémentaires"
                    id="incomplete-frais-sup-beneficiaire-message-input"
                    maxlength="999"
                    rows="7"
                    @input="$v.refuseRemboursementFraisDeplacementMessage.$touch()"
                    :state="$v.refuseRemboursementFraisDeplacementMessage.$dirty ? !$v.refuseRemboursementFraisDeplacementMessage.$error : null"/>
                </b-form-group>
                <b-button
                  block :variant="incompleteRemboursementButtonStyle" class="mt-2"
                  :disabled="incompleteRemboursementInProcess || $v.refuseRemboursementMessage.$invalid || $v.refuseRemboursementMessage.$invalid"
                  @click='incompleteRemboursement'>
                    <b-spinner small label="refuse beneficiaire Spinner" v-if="incompleteRemboursementInProcess"></b-spinner>
                    {{ incompleteRemboursementButtonText }}
                </b-button>
              </b-form>
            </b-modal>

            <b-modal ref="incompletedRemboursementModal" id="incompletedRemboursementModal" size="lg" hide-footer title="Vous avez bien marqué comme incomplète la demande du bénéficiaire">
              <div class="text-center">
                <p><strong>Vous avez bien marqué comme incomplète la demande du bénéficiaire.</strong></p>
                <p> Celui-ci sera invité à compléter / corriger sa demande de remboursement </p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('incompletedRemboursementModal')">Fermer</b-button>
              </div>
            </b-modal>

            <b-modal ref="refusedRemboursementModal" id="refusedRemboursementModal" size="lg" hide-footer title="Vous avez refusé la demande du bénéficiaire">
              <div class="text-center">
                <p><strong>Vous avez refusé la demande du bénéficiaire. </strong></p>
                <p>Un manager devra confirmer le refus de la demande du bénéficiaire.</p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('refusedRemboursementModal')">Fermer</b-button>
              </div>
            </b-modal>

            <b-modal ref="hasBeenDouteRemboursementModal" id="hasBeenDouteRemboursementModal" size="lg" hide-footer title="Vous avez bien marqué la demande comme étant en doute">
              <div class="text-center">
                <p><strong>Vous avez bien marqué la demande comme étant en doute, un manager va prendre en charge ce dossier. </strong></p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('hasBeenDouteRemboursementModal')">Fermer</b-button>
              </div>
            </b-modal>

            <b-modal ref="hasBeenMiseEnPaiementRemboursementModal" id="hasBeenMiseEnPaiementRemboursementModal" size="lg" hide-footer title="Vous avez bien marqué la demande comme mise en paiement">
              <div class="text-center">
                <p><strong>Vous avez bien marqué la demande comme mise en paiement. </strong></p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('hasBeenMiseEnPaiementRemboursementModal')">Fermer</b-button>
              </div>
            </b-modal>


            <!-- MODIFY JUSTIF FILE  -->
            <b-modal ref="modifyRemboursementJustifFileFraisDeplacementModal" id="modifyRemboursementJustifFileFraisDeplacementModal" size="lg" hide-footer>
              <template v-slot:modal-title>
                <strong> Modifier le fichier de calcul des frais supplémentaires </strong>
              </template>
              <p> Vous pouvez modifier le fichier de calcul lié au calcul des frais supplémentaires
              </p>
              <b-form>
                <b-form-group>
                    <b-form-file
                      class="text-truncate"
                      browse-text='Parcourir'
                      required
                      v-model="modifyRemboursementJustifFileFraisDeplacementDoc"
                      id="create-document-file-input"
                      placeholder="Choisissez un fichier"
                      drop-placeholder="Déposez un fichier ici"
                      @input="$v.modifyRemboursementJustifFileFraisDeplacementDoc.$touch()"
                      :state="$v.modifyRemboursementJustifFileFraisDeplacementDoc.$dirty ? !$v.modifyRemboursementJustifFileFraisDeplacementDoc.$error : null"
                    ></b-form-file>
                    <div class="form-error" v-if="!$v.modifyRemboursementJustifFileFraisDeplacementDoc.five_mega_file_size_validation">Attention, la taille de votre document ne doit pas dépasser 5 MB.</div>
                    <div class="form-error" v-if="!$v.modifyRemboursementJustifFileFraisDeplacementDoc.justif_file_extension_validation">Attention, votre fichier doit être au format pdf, xls ou xlsx</div>
                    <div class="form-error" v-if="!$v.modifyRemboursementJustifFileFraisDeplacementDoc.name.maxLength">Assurez-vous que le nom de votre fichier comporte au plus 100 caractères</div>
                    <div class="form-error" v-if="!$v.modifyRemboursementJustifFileFraisDeplacementDoc.name.minLength">Assurez-vous que le nom de votre fichier comporte au moins 2 caractères</div>
                </b-form-group>
                <b-button
                  block variant="outline-info" class="mt-2"
                  :disabled="modifyRemboursementJustifFileFraisDeplacementInProcess || $v.modifyRemboursementJustifFileFraisDeplacementDoc.$invalid"
                  @click='modifyRemboursementJustifFileFraisDeplacement'>
                    Modifier votre fichier
                </b-button>
              </b-form>
            </b-modal>

            <b-modal ref="modifiedRemboursementJustifFileModal" id="modifiedRemboursementJustifFileModal" size="lg" hide-footer title="Fichier de calcul modifié">
              <div class="text-center">
                <p>
                  <strong>
                    Le fichier de calcul lié à ce remboursement a bien été modifié.
                  </strong>
                </p>
                <b-button class="mt-3" variant="outline-success" @click="$bvModal.hide('modifiedRemboursementJustifFileModal')">Fermer</b-button>
              </div>
            </b-modal>

            <div v-if="remboursement.conseiller && remboursement.beneficiaire.muna">
              <div v-if="connectedConseillerUsername === remboursement.conseiller.user.username">
                <div v-if="remboursement.state == '2ONGOING' || remboursement.state == '3INCOMPLET' || remboursement.state == '4CREFUSED' || remboursement.state == 'DOUTE'">
                  <div class="row mt-2">
                    <div class="col-12 col-md-6 text-center">
                      <b-button v-b-toggle.refuse-remboursement-collapse variant="outline-danger" class="option-button mt-2" block>
                        <span class="when-closed">Refuser ou marquer la demande comme incomplète / doute</span>
                        <span class="when-open">Annuler</span>
                      </b-button>
                    </div>
                    <div class="col-12 col-md-6 text-center">
                      <b-button
                        variant="outline-success" class="option-button mt-2" block
                        @click="$bvModal.show('validateRemboursementModal')">
                          Valider la demande du bénéficiaire
                      </b-button>
                    </div>
                  </div>

                  <b-collapse id="refuse-remboursement-collapse" class="mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <b-button
                          block variant="outline-secondary" class="mt-2"
                          :disabled="remboursement.state == 'DOUTE'"
                          @click="douteRemboursement">
                            Passer la demande au statut "en doute"
                        </b-button>
                      </div>
                      <div class="col-md-4">
                        <b-button
                          block variant="outline-danger" class="mt-2"
                          :disabled="remboursement.state == '3INCOMPLET'"
                          @click="$bvModal.show('incompleteRemboursementModal')">
                            Marquer la demande comme incomplète
                        </b-button>
                      </div>
                      <div class="col-md-4">
                        <b-button
                          block variant="outline-danger" class="mt-2"
                          :disabled="remboursement.state == '4CREFUSED'"
                          @click="$bvModal.show('refuseRemboursementModal')">
                              Refuser la demande du bénéficiaire
                        </b-button>
                      </div>
                    </div>
                  </b-collapse>
                  <hr>
                </div>
                <div v-else-if="remboursement.state === '7MVALIDATED'">
                  <div class="row mt-2">
                    <div class="col-12 text-center">
                      <b-button
                        block :variant="miseEnPaiementRemboursementButtonStyle" class="mt-2"
                        :disabled="miseEnPaiementRemboursementInProcess"
                        @click='miseEnPaiementRemboursement'>
                          <b-spinner small label="refuse beneficiaire Spinner" v-if="miseEnPaiementRemboursementInProcess"></b-spinner>
                          {{ miseEnPaiementRemboursementButtonText }}
                      </b-button>
                    </div>
                  </div>
                  <hr>
                </div>
              </div>
              <div v-else>
                <strong class="text-danger">
                  Attention ce dossier ne vous est pas attribué, vous ne pouvez donc pas le faire changer de statut.
                  Veuillez vous attribuer le dossier pour pouvoir le modifier
                </strong>
                <div class="row mt-2">
                  <div class="col-12 text-center">
                    <b-button
                      block pill variant="outline-danger" class="mt-2"
                      @click='attributeRemboursementToHimself'>
                        M'attribuer ce dossier
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="!remboursement.conseiller">
              <strong class="text-danger">
                Attention ce dossier ne vous est pas attribué, vous ne pouvez donc pas le faire changer de statut.
                Veuillez vous attribuer le dossier pour pouvoir le modifier
              </strong>
              <div class="row mt-2">
                <div class="col-12 text-center">
                  <b-button
                    block pill variant="outline-danger" class="mt-2"
                    @click='attributeRemboursementToHimself'>
                      M'attribuer ce dossier
                  </b-button>
                </div>
              </div>
            </div>
            <div v-else>
              <strong class="text-danger">
                Veuillez renseigner un numéro de muna pour pouvoir valider ou refuser le dossier
              </strong>
            </div>


            <loading
              :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="true"
              color="#BB3634">
            </loading>

            <div class="row mt-2">
              <div class="col-12">
                <strong style="font-size: 1.2em;"> Statut actuel de la demande du bénéficiaire :
                <span v-if="remboursement.state == '1CREATED'" class="text-secondary"> En cours de remplissage par le bénéficiaire</span>
                <span v-if="remboursement.state == '2ONGOING'" class="text-primary"> A valider par le conseiller</span>
                <span v-if="remboursement.state == '3INCOMPLET'" class="text-danger"> Incomplète, à reremplir par le bénéficiaire </span>
                <span v-if="remboursement.state == 'DOUTE'" class="text-secondary"> En doute, à traiter par un manager</span>
                <span v-if="remboursement.state == '4CREFUSED'" class="text-danger"> Refusée par le conseiller, en attente de confirmation du manager</span>
                <span v-if="remboursement.state == '5CVALIDATED'" class="text-success"> Validée par le conseiller, en attente de confirmation du manager</span>
                <span v-if="remboursement.state == '6MREFUSED'" class="text-danger"> Refusée par le manager</span>
                <span v-if="remboursement.state == '7MVALIDATED'" class="text-success"> Validée par le manager</span>
                <span v-if="remboursement.state == '8PAIEMENT'" class="text-success"> Mise en paiement</span>
                </strong>
              </div>
            </div>

            <div v-if="remboursement.justif_file" class="row mt-2 mb-2">
              <div class="col-12">
                <a href="#" @click='downloadRemboursementProtectedJustifFile()'>
                  Télécharger le fichier de calcul des frais de garde
                </a>
              </div>
            </div>


            <div v-if="remboursement.want_frais_deplacement && remboursement.justif_file_frais_deplacement" class="row mt-2 mb-2">
              <div class="col-12">
                <a href="#" @click='downloadRemboursementProtectedJustifFileFraisDeplacement()'>
                  Télécharger le fichier de calcul des frais supplémentaires
                </a>
                <b-button
                  v-if="remboursement.state === '2ONGOING' || remboursement.state == '3INCOMPLET' || remboursement.state === '4CREFUSED' || remboursement.state === '5CVALIDATED'"
                  variant="outline-primary" class="ml-2" size="sm"
                  @click='$refs.modifyRemboursementJustifFileFraisDeplacementModal.show()'>
                  <v-icon name="edit"/>
                </b-button>
              </div>
            </div>
            <div v-else-if="remboursement.want_frais_deplacement && remboursement.state == '5CVALIDATED'" class="row mt-2 mb-2">
              <div class="col-12">
                Modifier le fichier de calcul des frais supplémentaires
                <b-button
                  variant="outline-primary" class="ml-2" size="sm"
                  @click='$refs.modifyRemboursementJustifFileFraisDeplacementModal.show()'>
                  <v-icon name="edit"/>
                </b-button>
              </div>
            </div>


            <div class="row">
              <div class="col-12">
                Date du dernier changement de statut de la demande :
                  <strong>{{$dayjs(remboursement.state_changed_date).format('D MMM YYYY')}}</strong>
              </div>
            </div>
            <div 
              v-if="remboursement.relance_sent"
              class="row"
            >
              <div class="col-12">
                Relance envoyée au bénéficiaire
                <span 
                  v-if="remboursement.relance_date"
                >
                  le <strong>{{$dayjs(remboursement.relance_date).format('D MMM YYYY')}}</strong>
                </span>
              </div>
            </div>
            <div v-if="remboursement.state != '7MVALIDATED' && remboursement.state != '8PAIEMENT'" class="row">
              <div v-if="remboursement.refused_message" class="col-12">
                Dans le cas où cette demande a déjà été refusée, voici le message de refus laissé : <br>
                <em>{{ remboursement.refused_message }}</em>
              </div>
              <div v-if="remboursement.refused_message_frais_deplacement && remboursement.state !== '5CVALIDATED'" class="col-12">
                Message de refus laissé pour les frais supplémentaires: <br>
                <em>{{ remboursement.refused_message_frais_deplacement }}</em>
              </div>
            </div>
            <div v-if="remboursement.conseiller" class="row">
              <div class="col-12">
                Conseiller attribué :
                <strong>{{ remboursement.conseiller.user.first_name }} {{ remboursement.conseiller.user.last_name}}</strong>
              </div>
            </div>
            <div v-if="remboursement.state == '5CVALIDATED' || remboursement.state == '7MVALIDATED' || remboursement.state == '8PAIEMENT'" class="row mt-3 mb-3">
              <div class="col-12">
                <strong style="font-size: 1.2em;">
                  La demande de remboursement pour {{monthRender(remboursement.month)}} {{remboursement.year}} a été validée.<br>
                  Le montant alloué est de <span class="text-success">{{remboursement.montant}} €</span><br>
                </strong>
                <span v-if="remboursement.are_frais_deplacement_validated">
                  Le montant alloué au titre des frais supplémentaires est de <strong>{{remboursement.montant_frais_deplacement}} €</strong>
                </span>
                <span v-else-if="remboursement.want_frais_deplacement">
                  La prise en charge des frais supplémentaires a été refusée.<br>
                  Message de refus laissé pour les frais supplémentaires: <br>
                  <em>{{ remboursement.refused_message_frais_deplacement }}</em>
                </span>
              </div>
            </div>


            <p class="mt-3">
              <strong> Modes de gardes concernés :</strong>
              <ul>
                <li v-if="remboursement.is_creche">Crèche ou périscolaire</li>
                <li v-if="remboursement.is_gad_pajemploi">Garde à domicile / Assitante maternelle via PAJEMPLOI</li>
                <li v-if="remboursement.is_gad_cesu">Garde à domicile via CESU</li>
                <li v-if="remboursement.is_gad_structure">Garde à domicile via une structure de garde d'enfants / Microcrèche</li>
                <li v-if="remboursement.is_pair">Jeune fille au pair</li>
              </ul>
            </p>

            <p class="mt-3">
              <strong v-if="remboursement.want_frais_deplacement">Ce bénéficiaire a fait une demande de frais supplémentaires</strong>
              <strong v-else>Pas de demande de frais supplémentaires</strong>
            </p>

            <strong> Enfants concernés : </strong>
            <b-table
              responsive class="align-middle text-center mt-4"
              headVariant="light"
              :fields="childrenRenderFields"
              :items="remboursement.children"
              >
              <template v-slot:cell(birthdate)="data">
                {{$dayjs(data.item.birthdate).format('D MMM YYYY')}}
              </template>
              <template v-slot:cell(age)="data">
                {{calculateAge(data.item.birthdate)}} ans
              </template>
            </b-table>

            <strong> Accords du bénéficiaire : </strong>
            <b-table
              responsive class="align-middle text-center mt-4 cursor-table"
              headVariant="light" hover
              :fields="accordsRenderFields"
              :items="remboursement.beneficiaire.accords"
              @row-clicked="accordRowClicked"
              >
              <template v-slot:cell(state)="data">
                {{accordStateRender(data.item.state)}}
              </template>
            </b-table>



            <div class="row mt-1">
              <div class="col-12">
                <h2>Informations à renseigner par le conseiller </h2>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12">
                <b-form-group id="muna-form-group" label="Muna du bénéficiaire" label-for="muna-form-input"
                  label-cols-md="4" label-cols-lg="4" class="mandatory-input">
                  <b-form-input
                    id="muna-form-input"
                    type="text" maxlength="11"
                    v-model.trim.lazy="beneficiaireMuna"
                    @input="$v.beneficiaireMuna.$touch()"
                    :state="$v.beneficiaireMuna.$dirty ? !$v.beneficiaireMuna.$error : null"
                    placeholder="XXXXXXXX.XX">
                  </b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-12">
                <b-form-group id="memo-form-group" label="Mettre un mémo" label-for="memo-form-input"
                  label-cols-md="4" label-cols-lg="4">
                  <b-form-textarea
                    id="memo-form-input"
                    type="text" maxlength="599"
                    placeholder="Commentaire"
                    v-model.trim.lazy="beneficiaireMemo"
                    @input="$v.beneficiaireMemo.$touch()"
                    :state="$v.beneficiaireMemo.$dirty ? !$v.beneficiaireMemo.$error : null">
                  </b-form-textarea>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-12 text-center">
                <b-button
                  pill block :variant="saveMunaMemoMessageButtonStyle" size="sm"
                  @click='saveMunaMemoMessage'
                  :disabled="$v.beneficiaireMuna.$invalid || $v.beneficiaireMemo.$invalid">
                    <b-spinner small label="save muna Spinner" v-if="saveMunaMemoMessageInProcess"></b-spinner> {{ saveMunaMemoMessageButtonText }}
                </b-button>
              </div>
            </div>

            <conseillerRemboursementDocumentsProfile
              @actualizeRemboursement="conseillerGetRemboursement"
              v-bind:remboursement="remboursement"
              :montantCalculatedPerModeGarde="montantCalculatedPerModeGarde"
              :montantCalculatedPerFraisSup="montantCalculatedPerFraisSup"
            />

            <conseillerBeneficiaireProfile
              v-bind:beneficiaire="remboursement.beneficiaire"
            />


          </div>
        </div>
    </div>
  </div>
</template>

<script>

import { APIConseillerConnected } from '@/api/APIConseillerConnected'

import renderMixins from '@/mixins/renderMixins'
import { dateOperations } from '@/mixins/utilMixins'

import { validationMixin } from 'vuelidate'
import { required, requiredIf, minLength, maxLength, decimal, minValue, maxValue } from 'vuelidate/lib/validators'

import { five_mega_file_size_validation, justif_file_extension_validation, isMuna } from '@/validators/validators'

import formTitleComponent from '@/components/formTitleComponent'
import { calculateMontantPerModeGarde } from '@/services/gardeCaracteristiquesService'
import { calculateMontantPerFraisSup } from '@/services/fraisSupplementairesService'

const conseillerRemboursementDocumentsProfile = () => import('@/components/remboursement/conseillerRemboursementDocumentsProfile')
const conseillerBeneficiaireProfile = () => import('@/components/beneficiaire/conseillerBeneficiaireProfile')

const apiConseillerConnected = new APIConseillerConnected()

export default {
  name: 'ConseillerRemboursementProfile',
  components: {
    formTitleComponent,
    conseillerRemboursementDocumentsProfile,
    conseillerBeneficiaireProfile
  },
  mixins: [
    validationMixin,
    renderMixins,
    dateOperations
  ],
  data: function () {
    return {
      remboursement: {
        id: '',
        state: '1CREATED',
        state_changed_date: '',
        first_ask_validation_date: '',
        refused_message: '',
        refused_message_frais_deplacement: '',
        is_avis_situation_mandatory: false,
        is_teleactualisation_mandatory: false,
        is_attestation_paiement_mandatory: false,
        year: 2018,
        month: 1,
        montant:'0.00',
        montant_frais_deplacement: '0.00',
        justif_file: null,
        justif_file_frais_deplacement: null,
        justif_file_frais_deplacement_name: '',
        justif_file_name: '',
        is_creche: false,
        is_gad_pajemploi: false,
        is_gad_cesu: false,
        is_gad_structure: false,
        is_pair: false,
        children: [],
        frais_supplementaires: [],

        beneficiaire: {
          user: {
            first_name: '',
            last_name: '',
            username: '',
          },
          children: [],
          accords: [],
          remboursements: [],
          activite: 'ART',
          metier: '',
          other_first_names: '',
          address: '',
          code_postal: '',
          city: '',
          birthdate: '2020-01-01',
          phone_number: '',
          other_phone_number: '',
          situation: 'CELIB',
          conjoint_first_name: '',
          conjoint_last_name: '',
          is_conjoint_intermittent: false,
          muna: '',
          memo_message: '',
        },

        conseiller: {
          id: '',
          user: {
            first_name: '',
            last_name: '',
            username: ''
          }
        },
        are_frais_deplacement_validated: false,
        want_frais_deplacement: false
      },

      childrenRenderFields: [
        { key: "first_name", label: "Prénom"},
        { key: "last_name", label: "Nom"},
        { key: "birthdate", label: "Date de naissance"},
        { key: "age", label: "Age"},
      ],

      accordsRenderFields: [
        { key: "year", label: "Année"},
        { key: "state", label: "Etat"},
      ],

      isRemboursementLoading: true,

      // ------ MUNA ----------
      beneficiaireMuna: '',
      beneficiaireMemo: '',
      saveMunaMemoMessageButtonText: "Enregistrer le muna et votre mémo",
      saveMunaMemoMessageButtonStyle: "outline-info",
      saveMunaMemoMessageInProcess: false,


      // ------ VALIDATE REFUSE REMBOURSEMENT

      validateRemboursementMontant: '',
      validateRemboursementAreFraisDeplacementVaidated: false,
      validateRemboursementButtonText: "Valider la demande de remboursement du bénéficiaire",
      validateRemboursementButtonStyle: "outline-success",
      validateRemboursementInProcess: false,

      modifyRemboursementJustifFileFraisDeplacementDoc: [],
      modifyRemboursementJustifFileFraisDeplacementInProcess: false,

      refuseRemboursementMessage: '',
      refuseRemboursementFraisDeplacementMessage: '',
      refuseRemboursementButtonText: "Refuser la demande de remboursement du bénéficiaire",
      refuseRemboursementButtonStyle: "outline-danger",
      refuseRemboursementInProcess: false,

      incompleteRemboursementButtonText: "Signaler la demande de remboursement comme incomplète",
      incompleteRemboursementButtonStyle: "outline-danger",
      incompleteRemboursementInProcess: false,

      stateRemboursementInProcess: false,

      miseEnPaiementRemboursementButtonText: "Marquer comme mis en paiement",
      miseEnPaiementRemboursementButtonStyle: "outline-success",
      miseEnPaiementRemboursementInProcess: false,
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    connectedConseillerUsername () {
      return this.$store.state.user.userUsername
    },
    isLoading () {
      if (this.isRemboursementLoading || this.modifyRemboursementJustifFileFraisDeplacementInProcess || this.stateRemboursementInProcess) {
        return true
      }
      return false
    },
    totalMontantRembourseInYear () {
      var montant_rembourse = 0
      for (var i = 0; i < this.remboursement.beneficiaire.remboursements.length; i++) {
        if (this.remboursement.beneficiaire.remboursements[i].year === this.remboursement.year && (this.remboursement.beneficiaire.remboursements[i].state === '7MVALIDATED' || this.remboursement.beneficiaire.remboursements[i].state === '8PAIEMENT')) {
          montant_rembourse = montant_rembourse  + parseFloat(this.remboursement.beneficiaire.remboursements[i].montant)
        }
      }
      return montant_rembourse
    },
    totalMontantFraisSupplementaireInYear () {
      return this.remboursement.beneficiaire.remboursements.filter(
        (item) => item.year === this.remboursement.year && (item.state === '7MVALIDATED' || item.state === '8PAIEMENT')
      ).map(
        (item) => item.montant_frais_deplacement
      ).reduce(
        (partialSum, montant) => partialSum + montant, 0
      )
    },
    childrenIds () {
      return this.remboursement.children.map((item) => item.id)
    },
    montantCalculatedPerModeGarde () {
      return calculateMontantPerModeGarde(this.remboursement, this.childrenIds)
    },
    montantCalculatedPerFraisSup () {
      return calculateMontantPerFraisSup(this.remboursement)
    },
    montantPlafond () {
      /* Pour les bénéficiaires célibataire, séparés, ou divorcés : 6 000€ / an 
      Pour les autres 3 000€ / an */
      if (
        (
          this.remboursement.beneficiaire.situation === 'CELIB' || 
          this.remboursement.beneficiaire.situation === 'DIV' || 
          this.remboursement.beneficiaire.situation === 'SEP'
        ) &&
        this.remboursement.beneficiaire.is_eligibile_to_new_plafond_remboursement_annule
        ) {
        return 6000
      }
      else {
        return 3000
      }
    },
    montantTotal () {
      const montant = Object.values(this.montantCalculatedPerModeGarde).reduce((partialSum, montant) => partialSum + montant, 0)
      if (montant + this.totalMontantRembourseInYear > this.montantPlafond) {
        return (this.montantPlafond - this.totalMontantRembourseInYear).toFixed(2)
      }
      else {
        return montant.toFixed(2)
      }
    },
    montantFraisSupTotal () {
      const montant = Object.values(this.montantCalculatedPerFraisSup).reduce((partialSum, montant) => partialSum + montant, 0)
      if (montant + this.totalMontantFraisSupplementaireInYear > 1000) {
        return (1000 - this.totalMontantFraisSupplementaireInYear).toFixed(2)
      }
      else {
        return montant.toFixed(2)
      }
    },
    isErrorMessageTotalMontant () {
      const montant = Object.values(this.montantCalculatedPerModeGarde).reduce((partialSum, montant) => partialSum + montant, 0)
      return montant + this.totalMontantRembourseInYear > this.montantPlafond
    },
    isErrorMessageTotalMontantFraisSup () {
      const montant = Object.values(this.montantCalculatedPerFraisSup).reduce((partialSum, montant) => partialSum + montant, 0)
      return montant + this.totalMontantFraisSupplementaireInYear > 1000
    }
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.conseillerGetRemboursement()
  },
  validations: {
    validateRemboursementMontant: {
      required,
      decimal,
      minValue: minValue(1),
      maxValue: maxValue(100000000)
    },
    modifyRemboursementJustifFileFraisDeplacementDoc: {
      required,
      five_mega_file_size_validation,
      justif_file_extension_validation,

      name :{
        maxLength: maxLength(100),
        minLength: minLength(2)
      }
    },
    refuseRemboursementMessage: {
      required: requiredIf(function() {
        return !this.refuseRemboursementFraisDeplacementMessage
      }),
      minLength: minLength(2),
      maxLength: maxLength(999)
    },
    refuseRemboursementFraisDeplacementMessage: {
      minLength: minLength(2),
      maxLength: maxLength(999)
    },
    beneficiaireMuna: {
      required,
      minLength: minLength(11),
      maxLength: maxLength(11),
      validMuna(value) {
        return isMuna(value)
      }
    },
    beneficiaireMemo: {
      minLength: minLength(2),
      maxLength: maxLength(599),
    },
  },

  methods: {
    // ------------ GLOBALS --------------
    validateRemboursementMontantCorrection () {
      var floatValidateRemboursementMontant = parseFloat(this.validateRemboursementMontant.replace(',', '.'))
      if (isNaN(floatValidateRemboursementMontant)) {
        this.validateRemboursementMontant = ''
      }
      else {
        this.validateRemboursementMontant = floatValidateRemboursementMontant.toFixed(2)
      }
    },

    // ------------ GETTERS --------------
    conseillerGetRemboursement () {
      this.isRemboursementLoading = true
      apiConseillerConnected.getRemboursement(this.token, this.$route.params.remboursement_id)
      .then((result) => {
        this.remboursement = result.data
        this.validateRemboursementMontant = result.data.montant
        this.validateRemboursementAreFraisDeplacementVaidated = result.data.are_frais_deplacement_validated
        this.refuseRemboursementMessage = result.data.refused_message
        this.refuseRemboursementFraisDeplacementMessage = result.data.refused_message_frais_deplacement
        this.beneficiaireMuna = result.data.beneficiaire.muna
        this.beneficiaireMemo = result.data.beneficiaire.memo_message
      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.isRemboursementLoading = false
      })
    },


    // ------------ MODIFICATION ---------------

    saveMunaMemoMessage () {
      this.saveMunaMemoMessageButtonText = "Enregistrement en cours"
      this.saveMunaMemoMessageButtonStyle = "secondary"
      this.saveMunaMemoMessageInProcess = true
      apiConseillerConnected.saveMunaMemoMessage(
        this.token, this.remboursement.beneficiaire.id, this.beneficiaireMuna, this.beneficiaireMemo)
      .then(() => {
        this.conseillerGetRemboursement()
      })
      .catch((error) => {
        if (error.response && error.response.data.muna && error.response.data.muna[0] === 'MUNA_ALREADY_EXISTING') {
          this.$refs.munaExistingErrorModal.show()
        }
        else {
          this.$refs.globalAPILoadingErrorModal.show()
        }
      })
      .finally(() => {
        this.saveMunaMemoMessageButtonText = "Enregistrer le muna et votre mémo"
        this.saveMunaMemoMessageButtonStyle = "outline-info"
        this.saveMunaMemoMessageInProcess = false
      })
    },

    attributeRemboursementToHimself () {
      this.isRemboursementLoading = true
      apiConseillerConnected.attributeRemboursementsToHimself(this.token, [this.$route.params.remboursement_id])
      .then(() => {
        this.conseillerGetRemboursement()
      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.isRemboursementLoading = false
      })
    },


    // -------------- VALIDATION REFUS -------------

    validateRemboursement () {
      this.validateRemboursementButtonText = "Validation en cours"
      this.validateRemboursementButtonStyle = "secondary"
      this.validateRemboursementInProcess = true
      apiConseillerConnected.validateRemboursement(this.token, this.remboursement.id,
                                                   this.montantTotal,
                                                   this.validateRemboursementAreFraisDeplacementVaidated,
                                                   this.montantFraisSupTotal,
                                                   this.refuseRemboursementFraisDeplacementMessage)
      .then(() => {
        this.conseillerGetRemboursement()
        this.$refs.validateRemboursementModal.hide()
        this.$refs.validatedRemboursementModal.show()
      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.validateRemboursementButtonText = "Valider la demande de remboursement du bénéficiaire"
        this.validateRemboursementButtonStyle = "outline-success"
        this.validateRemboursementInProcess = false
      })
    },

    refuseRemboursement () {
      this.refuseRemboursementButtonText = "Envoi en cours"
      this.refuseRemboursementButtonStyle = "secondary"
      this.refuseRemboursementInProcess = true

      apiConseillerConnected.refuseRemboursement(this.token, this.remboursement.id, this.refuseRemboursementMessage,
                                                 this.refuseRemboursementFraisDeplacementMessage)
      .then(() => {
        this.conseillerGetRemboursement()
        this.$refs.refusedRemboursementModal.show()
      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.$root.$emit('bv::toggle::collapse', 'refuse-remboursement-collapse')
        this.$refs.refuseRemboursementModal.hide()
        this.refuseRemboursementButtonText = "Refuser la demande de remboursement du bénéficiaire"
        this.refuseRemboursementButtonStyle = "outline-danger"
        this.refuseRemboursementInProcess = false
      })
    },

    incompleteRemboursement () {
      this.incompleteRemboursementButtonText = "Refus en cours"
      this.incompleteRemboursementButtonStyle = "secondary"
      this.incompleteRemboursementInProcess = true

      apiConseillerConnected.incompleteRemboursement(this.token, this.remboursement.id, this.refuseRemboursementMessage,
                                                     this.refuseRemboursementFraisDeplacementMessage)
      .then(() => {
        this.conseillerGetRemboursement()
        this.$refs.incompletedRemboursementModal.show()
      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.$root.$emit('bv::toggle::collapse', 'refuse-remboursement-collapse')
        this.$refs.incompleteRemboursementModal.hide()
        this.incompleteRemboursementButtonText = "Signaler la demande de remboursement comme incomplète"
        this.incompleteRemboursementButtonStyle = "outline-danger"
        this.incompleteRemboursementInProcess = false
      })
    },

    douteRemboursement () {
      this.stateRemboursementInProcess = true
      apiConseillerConnected.douteRemboursement(this.token, this.remboursement.id)
      .then(() => {
        this.conseillerGetRemboursement()
        this.$refs.hasBeenDouteRemboursementModal.show()
      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.$root.$emit('bv::toggle::collapse', 'refuse-remboursement-collapse')
        this.stateRemboursementInProcess = false
      })
    },

    miseEnPaiementRemboursement () {
      this.miseEnPaiementRemboursementButtonText = "En cours"
      this.miseEnPaiementRemboursementButtonStyle = "secondary"
      this.miseEnPaiementRemboursementInProcess = true

      apiConseillerConnected.miseEnPaiementRemboursement(this.token, this.remboursement.id)
      .then(() => {
        this.conseillerGetRemboursement()
        this.$refs.hasBeenMiseEnPaiementRemboursementModal.show()
      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.miseEnPaiementRemboursementButtonText = "Marquer comme mis en paiement"
        this.miseEnPaiementRemboursementButtonStyle = "outline-success"
        this.miseEnPaiementRemboursementInProcess = false
      })
    },



    modifyRemboursementJustifFileFraisDeplacement () {
      this.modifyRemboursementJustifFileFraisDeplacementInProcess = true
      apiConseillerConnected.modifyRemboursementJustifFileFraisDeplacement(this.token, this.remboursement.id, this.modifyRemboursementJustifFileFraisDeplacementDoc)
      .then(() => {
        this.conseillerGetRemboursement()
        this.$refs.modifyRemboursementJustifFileFraisDeplacementModal.hide()
        this.$refs.modifiedRemboursementJustifFileModal.show()
      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.modifyRemboursementJustifFileFraisDeplacementInProcess = false
      })
    },

    downloadRemboursementProtectedJustifFile () {
      apiConseillerConnected.downloadRemboursementProtectedJustifFile(this.token, this.remboursement.id)
      .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.remboursement.justif_file_name); //or any other extension
          document.body.appendChild(link);
          link.click();
        }, () => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
    },

    downloadRemboursementProtectedJustifFileFraisDeplacement () {
      apiConseillerConnected.downloadRemboursementProtectedJustifFileFraisDeplacement(this.token, this.remboursement.id)
      .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.remboursement.justif_file_frais_deplacement_name); //or any other extension
          document.body.appendChild(link);
          link.click();
        }, () => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
    },

    accordRowClicked(item) {
      var routeData = this.$router.resolve('/conseiller-dashboard/accord/' + item['id']);
      window.open(routeData.href, '_blank');
    },
  }
}
</script>
<style>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.minWidthTd {
   white-space: nowrap;
}
.ui-helper-center {
    text-align: center;
}
.table thead th {
  vertical-align: middle !important;
}
.cursor-table tr {
  cursor: pointer;
}

</style>
