export function calculateMontantPerFraisSup(remboursement) {

    const montants = {}

    // For transport 
    montants['TRANSPORT'] = remboursement.frais_supplementaires.filter(
        (item) => item.type === 'TRANSPORT'
    ).map(
        (frais) => frais.montant
    ).reduce((montant, fraisMontant) => {
        return montant + Number(fraisMontant)
    }, 0) / 2
    

    // Aggregate frais_supplementaires per date for type HEBERGEMENT
    montants['HEBERGEMENT'] = remboursement.frais_supplementaires.filter(
        (item) => item.type === 'HEBERGEMENT'
    ).reduce((montant, frais) => {   
        const montantTotalToRembourse = frais.montant * frais.nb_nuits / 2
        const limite = 50 * frais.nb_nuits // 50€ max par nuit
        return montant + Math.min(montantTotalToRembourse, limite)
    }, 0)


    // Aggregate frais_supplementaires per date for type REPAS
    const fraisSupRepas = remboursement.frais_supplementaires.filter(
        (item) => item.type === 'REPAS'
    ).reduce((acc, frais) => {
        if (acc[frais.date]) {
            acc[frais.date] = acc[frais.date] + Number(frais.montant)
        } else {
            acc[frais.date] = Number(frais.montant)
        }
        return acc
    }, {})

    montants['REPAS'] = Object.values(fraisSupRepas).reduce((montant, frais) => {
        const montantTotalToRembourse = frais / 2
        const limite = 19 // 19€ max par jour
        return montant + Math.min(montantTotalToRembourse, limite)
    }, 0)

    return montants
}