export function calculateMontantPerModeGarde(remboursement, childrenIds) {

  const montants = {}
  if (remboursement.is_creche) {
    montants['CRECHE'] = parseFloat(remboursement.garde_caracteristiques
      .filter((item) => item.type === 'CRECHE' && childrenIds.includes(item.child) && !item.child_not_concerned)
      .reduce((montant, gardeCaracteristique) => {
        if (gardeCaracteristique.nb_hours_garde && Number(gardeCaracteristique.nb_hours_garde) > 0) {
          var nb_hours_taken_into_account = Math.min(Number(gardeCaracteristique.nb_hours_worked_months), Number(gardeCaracteristique.nb_hours_garde))
          if (gardeCaracteristique.taux_horaire && Number(gardeCaracteristique.taux_horaire) > 0) {
            montant = montant + 0.5 * nb_hours_taken_into_account * Number(gardeCaracteristique.taux_horaire)
          }
          else if (gardeCaracteristique.cout_mensuel && Number(gardeCaracteristique.cout_mensuel) > 0) {
            montant = montant + 0.5 * Number(gardeCaracteristique.cout_mensuel) * nb_hours_taken_into_account / Number(gardeCaracteristique.nb_hours_garde)
          }
        }
        return montant
      }, 0)
     .toFixed(2))
  }

  if (remboursement.is_gad_pajemploi) {
    montants['GAD_PAJEMPLOI'] = parseFloat(remboursement.garde_caracteristiques
      .filter((item) => item.type === 'GAD_PAJEMPLOI' && childrenIds.includes(item.child) && !item.child_not_concerned)
      .reduce((montant, gardeCaracteristique) => {
        if (gardeCaracteristique.nb_hours_garde && Number(gardeCaracteristique.nb_hours_garde) > 0) {
          var nb_hours_taken_into_account = Math.min(Number(gardeCaracteristique.nb_hours_worked_months), Number(gardeCaracteristique.nb_hours_garde))
          montant = montant + 0.5 * (Number(gardeCaracteristique.salaire_net) + Number(gardeCaracteristique.reste_a_charge_cotisations) - Number(gardeCaracteristique.cmg_mois)) *
                    nb_hours_taken_into_account / Number(gardeCaracteristique.nb_hours_garde)
        }
        return montant
      }, 0)
     .toFixed(2))
  }

  if (remboursement.is_gad_cesu) {
    montants['GAD_CESU'] = parseFloat(remboursement.garde_caracteristiques
      .filter((item) => item.type === 'GAD_CESU' && childrenIds.includes(item.child) && !item.child_not_concerned)
      .reduce((montant, gardeCaracteristique) => {
        if (gardeCaracteristique.nb_hours_garde && Number(gardeCaracteristique.nb_hours_garde) > 0) {
          var nb_hours_taken_into_account = Math.min(Number(gardeCaracteristique.nb_hours_worked_months), Number(gardeCaracteristique.nb_hours_garde))
          montant = montant +
                    0.5 * (Number(gardeCaracteristique.salaire_net) + Number(gardeCaracteristique.reste_a_charge_cotisations) - Number(gardeCaracteristique.nb_hours_garde) * 2) * nb_hours_taken_into_account
                    / Number(gardeCaracteristique.nb_hours_garde)
        }
        return montant
      }, 0)
     .toFixed(2))
  }

  if (remboursement.is_gad_structure) {
    montants['GAD_STRUCTURE'] = parseFloat(remboursement.garde_caracteristiques
      .filter((item) => item.type === 'GAD_STRUCTURE' && childrenIds.includes(item.child) && !item.child_not_concerned)
      .reduce((montant, gardeCaracteristique) => {
        if (gardeCaracteristique.nb_hours_garde && Number(gardeCaracteristique.nb_hours_garde) > 0) {
          var nb_hours_taken_into_account = Math.min(Number(gardeCaracteristique.nb_hours_worked_months), Number(gardeCaracteristique.nb_hours_garde))
          montant = montant + 0.5 * (Number(gardeCaracteristique.cout_mensuel) - Number(gardeCaracteristique.cmg_mois)) *
                    nb_hours_taken_into_account / Number(gardeCaracteristique.nb_hours_garde)
        }
        return montant
      }, 0)
     .toFixed(2))
  }

  if (remboursement.is_pair) {
    montants['PAIR'] = parseFloat(remboursement.garde_caracteristiques
      .filter((item) => item.type === 'PAIR' && childrenIds.includes(item.child) && !item.child_not_concerned)
      .reduce((montant, gardeCaracteristique) => {
        if (gardeCaracteristique.nb_hours_garde && Number(gardeCaracteristique.nb_hours_garde) > 0) {
          var nb_hours_taken_into_account = Math.min(Number(gardeCaracteristique.nb_hours_worked_months), Number(gardeCaracteristique.nb_hours_garde))
          montant = montant + 0.5 * (Number(gardeCaracteristique.salaire_net) + Number(gardeCaracteristique.reste_a_charge_cotisations) - Number(gardeCaracteristique.cmg_mois)) *
                    nb_hours_taken_into_account / Number(gardeCaracteristique.nb_hours_garde)
        }
        return montant
      }, 0)
     .toFixed(2))
  }

  return montants
}
